body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-area {
  min-height: calc( 100vh - 300px);
  margin: 0 70px;
  border-radius: 25px;
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.sub-footer{
  margin: 0 70px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
@media (max-width : 720px) {
  .main-area {
    min-height: calc( 100vh - 300px);
    margin: 0 20px;
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
  }
  .sub-footer{
    margin: 0 20px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.main-section {
  padding: 20px;
  padding-top: 40px;
}
.padding-top-20 {
    padding-top: 20px;
}

.padding-top-5 {
  padding-top: 5px;
}

