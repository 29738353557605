.Retngulo-4 {
  width: 1110px;
  height: 532px;
  border-radius: 20px;
  border: solid 2px #2c1c62;
}

.Retngulo-Parcela-12 {
  height: 45px;
  padding-top: 8px;
  padding-left: 27px;
  background-color: #e9355f;
}

.confirm-button {
  width: 100%;
  button {
    border-radius: 10px;
    background-color: #281756;
    padding: 10px;
    width: 100%;
    color: white;
    cursor: pointer;
  }
  button:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }
}
.cancel-button {
  width: 100%;
  button {
    border-radius: 10px;
    background-color: #ec4747;
    padding: 10px;
    width: 100%;
    color: white;
    cursor: pointer;
  }
  button:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }
}

.Retngulo-12 {
  height: 45px;
  background-color: #b6c1f0;
}

.Nome-Tab-Escura {
  padding-top: 13px;
  padding-left: 27px;
  height: 17px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #2b2b2b;
}

.Nome-Tab-Clara {
  height: 45px;
  padding-top: 13px;
  padding-left: 27px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #2b2b2b;
  font-weight: normal;
  background-color: #ebeefa;
}

.Tab-Valor-Parcelamento {
  height: 45px;
  background-color: #e9355f;
  opacity: 1;
  position: absolute;
  left: 144px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}

.Text-Value-Installment {
  height: 17px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Image-Center {
  position: relative;
  text-align: center;
  top: 10%;
}

.Responsive {
  max-width: 100%;
  height: auto;
}
