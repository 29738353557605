#main-area {
  border: 2px solid #2c1c62;
  border-bottom: none;
  background-image: url('../../assets/images/Retângulo 24.png');
  .blank_label {
    height: 28px;
  }

  .checkbox {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    text-align: center;
  }

  .checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 20px;
  }

  .checkbox label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    margin-left: -20px;
    margin-top: 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 16px;
    font-size: 18px;
    color: #00923d;
  }

  .checkbox input[type='checkbox'] {
    opacity: 0;
    z-index: 1;
  }

  .checkbox input[type='checkbox']:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  .checkbox input[type='checkbox']:checked + label::after {
    font-family: 'FontAwesome';
    content: '\f00c';
  }

  .checkbox input[type='checkbox']:disabled + label {
    opacity: 0.65;
  }

  .checkbox input[type='checkbox']:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  .checkbox.checkbox-circle label::before {
    border-radius: 50%;
  }

  .checkbox.checkbox-inline {
    margin-top: 0;
  }

  .checkbox-primary input[type='checkbox']:checked + label::before {
    background-color: #428bca;
    border-color: #428bca;
  }

  .checkbox-primary input[type='checkbox']:checked + label::after {
    color: #fff;
  }

  .checkbox-danger input[type='checkbox']:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .checkbox-danger input[type='checkbox']:checked + label::after {
    color: #fff;
  }

  .checkbox-info input[type='checkbox']:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .checkbox-info input[type='checkbox']:checked + label::after {
    color: #fff;
  }

  .checkbox-warning input[type='checkbox']:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .checkbox-warning input[type='checkbox']:checked + label::after {
    color: #fff;
  }

  .checkbox-success input[type='checkbox']:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .checkbox-success input[type='checkbox']:checked + label::after {
    color: #fff;
  }
}
.check-area {
  border: 2px solid gray;
  border-radius: 10px;
  margin: 20px 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .check-text {
    h6 {
      margin: 0 !important;
    }
  }
  background-color: #fff;
  cursor: pointer;
}
label {
  font-weight: bold;
  //   width: 150px;
}
.card-detail {
  padding: 0px 0 0 50px;

  .card-type-img {
    display: flex;
    justify-content: space-between;
    .img-area {
      display: flex;
      img {
        display: block;
        height: 20px;
        object-position: center;
        object-fit: contain;
        padding: 0 10px;
      }
    }
  }
  @media (max-width: 720px) {
    .card-type-img {
      display: block;
      justify-content: space-between;
      .img-area {
        display: flex;
        img {
          display: block;
          height: 20px;
          object-position: center;
          object-fit: contain;
          padding: 0 10px;
        }
      }
    }
  }
  .card-other {
    display: flex;
    justify-content: space-between;
    div {
      width: 45%;
    }
  }
  input {
    width: 100%;
    border: 2px solid gray;
    padding: 10px;
    border-radius: 10px;
  }
  input:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }

  .check-button-area {
    display: flex;
    align-items: center;
    h6 {
      margin-left: 5px;
    }
  }
  .confirm-button {
    width: 100%;
    button {
      border-radius: 10px;
      background-color: #281756;
      padding: 10px;
      width: 100%;
      color: white;
      cursor: pointer;
    }
    button:focus {
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
  .cancel-button {
    width: 100%;
    button {
      border-radius: 10px;
      background-color: #ec4747;
      padding: 10px;
      width: 100%;
      color: white;
      cursor: pointer;
    }
    button:focus {
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
}

@media (max-width: 720px) {
  .card-detail {
    padding: 0;
  }
  .blank_label {
    display: none;
  }
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: green !important;
}

.sub-footer {
  // position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  background-color: #c9c9c8;
  border-bottom: 2px solid #2c1c62;
  border-left: 2px solid #2c1c62;
  border-right: 2px solid #2c1c62;
  .total-amount {
    float: right;
    width: 200px;
    height: 100%;
    background-color: #2b2b2b;
    border-bottom-right-radius: 20px;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 30px;
    img {
      display: block;
      object-position: center;
      object-fit: contain;
      height: 30px;
    }
  }
}

.sub-footer-empty {
  // position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  // background-color: #c9c9c8;
  background-image: url('../../assets/images/Retângulo 24.png');
  border-bottom: 2px solid #2c1c62;
  border-left: 2px solid #2c1c62;
  border-right: 2px solid #2c1c62;
  .total-amount {
    float: right;
    width: 20%;
    height: 100%;
    background-color: #2b2b2b;
    border-bottom-right-radius: 20px;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 30px;
    img {
      display: block;
      object-position: center;
      object-fit: contain;
      height: 30px;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  height: 100vh;
  padding: 80;
  text-align: center;
  background: white;
  padding-top: 20%;
}
.loaderImg {
  margin-bottom: 20;
  align-items: center;
}

#popover-positioned-left {
  border: 2px solid gray;
  border-radius: 10px;
  padding: 10px 15px;
}

.check-area.active {
  border-color: #3f51b5 !important;
}

.textbox-error {
  border-color: #ec4747;
  text-align: 'center';
  max-width: '150px';
  margin-top: '5px';
  outline: 0;
  margin-right: '15px';
  border-radius: 10px;
}
