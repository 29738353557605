.footer-area {
    .text-area {
        padding: 10px 70px 30px 70px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .text {
            padding: 0 20px;
            p {
                margin: 0 !important;
            }
        }
        img {
            display: block;
            height: 40px;
            object-position: center;
            object-fit: contain;
        }
    }
    .logo-area {
        .row {
            margin: 0 !important;
        }
        padding: 10px;
        align-items: center;
        background-color: #281a55;
        color: white;
        .text {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            p {
                margin: 0 !important;
            }
        }
        .icon {
            img {
                display: block;
                height: 70px;
                object-position: center;
                object-fit: contain;
            }
        }
    }
}